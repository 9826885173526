var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex  flex-row justify-end w-full"},[_c('div',{staticClass:"flex flex-row justify-start w-full"},[_c('b-button',{staticClass:"button-theme-confirm",attrs:{"type":"is-link","icon-right":"microsoft-excel","loading":_vm.property.animation.isDownloadingFile,"disabled":_vm.property.animation.isDownloadingFile},on:{"click":function($event){return _vm.onDownloadFileIdn('csv')}}},[_vm._v(" Download CSV IDN")]),_c('b-button',{staticClass:"button-theme-confirm ml-2",attrs:{"type":"is-link","icon-right":"microsoft-excel","loading":_vm.property.animation.isDownloadingFile,"disabled":_vm.property.animation.isDownloadingFile},on:{"click":function($event){return _vm.onDownloadFileUs('csv')}}},[_vm._v(" Download CSV US")])],1),_c('div',{staticClass:"w-full flex flex-row justify-between",staticStyle:{"max-width":"150px"}},[_c('b-button',{staticClass:"button-theme-confirm",attrs:{"type":"is-success"},on:{"click":_vm.prosesCalculateProfitShare}},[_vm._v("Hitung")]),_c('b-button',{staticClass:"button-theme-confirm",attrs:{"type":"is-success","disabled":_vm.dataForm.validateProcess === false},on:{"click":_vm.prosesProfitShare}},[_vm._v(" Proses")])],1)]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.MInterOfficeAccount,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center p-1"},[(_vm.property.listElement.MInterOfficeAccount.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(
            _vm.property.listElement.MInterOfficeAccount.downloading === false
          )?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.MInterOfficeAccount.message ? _vm.property.listElement.MInterOfficeAccount.message : "DATA NOT FOUND")+" ")]):_vm._e()],1)]},proxy:true},{key:"footer",fn:function(){return [_c('th',{staticStyle:{"border-right":"0px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("Total")])]),_c('th',{staticStyle:{"border-left":"0px","border-right":"0px"}}),_c('th',{staticStyle:{"border-left":"0px","border-right":"0px"}}),_c('th',{staticStyle:{"border-left":"0px","border-right":"0px","text-align":"center"}}),_c('th',{staticStyle:{"border-left":"0px","border-right":"0px","text-align":"center"}}),_c('th',{staticStyle:{"border-left":"0px","border-right":"0px","text-align":"center"}},[_c('span',[_vm._v("Rp "+_vm._s(_vm.convertDecimalCurrency(_vm.dataForm.totalNominalRak)))])]),_c('th',{staticStyle:{"border-left":"0px","text-align":"center"}},[_c('span',[_vm._v("Rp "+_vm._s(_vm.convertDecimalCurrency(_vm.dataForm.totalProfitShare)))])])]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"p-2","field":"","label":"Pilih"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('div',[_c('b-checkbox',{attrs:{"type":"is-success","size":"is-small"},model:{value:(_vm.table.data.MInterOfficeAccount[props.index].selected),callback:function ($$v) {_vm.$set(_vm.table.data.MInterOfficeAccount[props.index], "selected", $$v)},expression:"table.data.MInterOfficeAccount[props.index].selected"}})],1)]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"p-2","field":"","label":"NO"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.rowNumber ? props.row.rowNumber : "-")+" ")]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","numeric":"","cell-class":"p-2","field":"","label":"SBB RAK"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.chartOfAccountCode ? props.row.chartOfAccountCode : "-")+" ")]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"p-2","field":"","label":"DESKRIPSI"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.description ? props.row.description : "-")+" ")]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","cell-class":"p-2","label":"%BAGHAS"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.profitSharePercentage ? props.row.profitSharePercentage : "-")+" ")]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"p-2","field":"","label":"RAK"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.nominal ? _vm.convertDecimalCurrency(props.row.nominal) : 0)+" ")]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"p-2","field":"","label":"BAGHAS RAK"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.profitShare ? _vm.convertDecimalCurrency(props.row.profitShare) : 0)+" ")]}}])})],1),_c('div',{staticClass:"flex  flex-row justify-end w-full"},[_c('b-checkbox',{attrs:{"disabled":_vm.dataForm.totalNominalRak === 0 || _vm.dataForm.totalProfitShare === 0,"type":"is-success","size":"is-small"},model:{value:(_vm.dataForm.validateProcess),callback:function ($$v) {_vm.$set(_vm.dataForm, "validateProcess", $$v)},expression:"dataForm.validateProcess"}},[_vm._v("Estimasi yang di tampilkan telah sesuai")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }