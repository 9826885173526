export default {
  name: "MInterOfficeAccount",
  data() {
    return {
      property: {
        animation: {
          submitLoading: false,
          isDownloadingFile: false,
        },
        listElement: {
          MInterOfficeAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        validateProcess: false,
        periode: "",
        totalNominalRak: 0,
        totalProfitShare: 0,
        selected: [],
      },
      table: {
        data: {
          MInterOfficeAccount: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFileUs(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        isComaDelimiter: true,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl:
            "inter-office-account/profit-share/download-csv/" +
            this.dataForm.periode,
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Estimasi_Bagi_Hasil_Rak.${
          fileType === "csv" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadFileIdn(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        isComaDelimiter: false,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl:
            "inter-office-account/profit-share/download-csv/" +
            this.dataForm.periode,
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Estimasi_Bagi_Hasil_Rak.${
          fileType === "csv" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async getSystemDate() {
      this.dataForm.periode = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    handleErrorGetMInterOfficeAccount(error) {
      console.log(error.response);
      this.table.data.MInterOfficeAccount = [];
      this.property.listElement.MInterOfficeAccount.rows = 0;
      this.property.listElement.MInterOfficeAccount.currentPage = 1;
      this.property.listElement.MInterOfficeAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetMInterOfficeAccount(resp) {
      this.table.data.MInterOfficeAccount = [];
      this.property.listElement.MInterOfficeAccount.rows = 0;
      this.property.listElement.MInterOfficeAccount.message = resp.data.message;
    },
    async getTableMInterOfficeAccount() {
      this.table.data.MInterOfficeAccount = [];
      this.property.listElement.MInterOfficeAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "inter-office-account/profit-share/" + this.dataForm.periode,
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.MInterOfficeAccount =
              resp.data.data.interOfficeProfitShareList;
            this.dataForm.totalNominalRak = resp.data.data.totalNominal;
            this.dataForm.totalProfitShare = resp.data.data.totalProfitShare;
          } else {
            this.failedGetMInterOfficeAccount(resp);
            this.dataForm.totalNominalRak = 0;
            this.dataForm.totalProfitShare = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMInterOfficeAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.MInterOfficeAccount.downloading = false;
        }, timeout);
      }
    },

    prosesCalculateProfitShare() {
      const data = {
        interOfficeProfitShareList: [],
      };
      data.interOfficeProfitShareList = [];
      this.table.data.MInterOfficeAccount.map((index) => {
        const rowNumber = index.rowNumber;
        const officeId = index.officeId;
        const description = index.description;
        const chartOfAccountCode = index.chartOfAccountCode;
        const nominal = index.nominal;
        const profitSharePercentage = index.profitSharePercentage;
        const profitShare = index.profitShare;
        const selected = index.selected;
        data.interOfficeProfitShareList.push({
          description,
          rowNumber,
          officeId,
          chartOfAccountCode,
          nominal,
          profitSharePercentage,
          profitShare,
          selected,
        });
      });

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hitung Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.listElement.MInterOfficeAccount.downloading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "inter-office-account/profit-share/recalculate",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.dataForm.totalNominalRak = resp.data.data.totalNominal;
                    this.dataForm.totalProfitShare =
                      resp.data.data.totalProfitShare;
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.listElement.MInterOfficeAccount.downloading = false;
              }, 1000);
            }
          },
        });
      }
    },
    prosesProfitShare() {
      const data = {
        interOfficeProfitShareList: [],
      };
      data.interOfficeProfitShareList = [];
      this.table.data.MInterOfficeAccount.map((index) => {
        const rowNumber = index.rowNumber;
        const officeId = index.officeId;
        const description = index.description;
        const chartOfAccountCode = index.chartOfAccountCode;
        const nominal = index.nominal;
        const profitSharePercentage = index.profitSharePercentage;
        const profitShare = index.profitShare;
        const selected = index.selected;
        data.interOfficeProfitShareList.push({
          description,
          rowNumber,
          officeId,
          chartOfAccountCode,
          nominal,
          profitSharePercentage,
          profitShare,
          selected,
        });
      });

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Proses Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.listElement.MInterOfficeAccount.downloading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "inter-office-account/profit-share",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.dataForm.totalNominalRak = resp.data.data.totalNominal;
                    this.dataForm.totalProfitShare =
                      resp.data.data.totalProfitShare;
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.listElement.MInterOfficeAccount.downloading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
  mounted() {
    this.getSystemDate();
    this.getTableMInterOfficeAccount();
  },
};
